#moyen-de-paiement {
  #billing-accounts {
    .other_bank_account {
      padding: 6px;
      .details {
        em {
          opacity: 0.7;
          font-size: 0.8rem;
          font-weight: 500;
        }
        strong {
          font-size: 0.75rem;
          margin-left: 3px;
        }
      }
    }

    #current_bank_account {
      border: 1px solid var(--color-gray3);
      p {
        margin-bottom: 0px;
      }
      #details {
        opacity: 0.8;

        p {
          font-size: 0.85rem;
        }
        strong {
          font-size: 0.85rem;
        }
      }
    }
  }
}
#facturation {
  overflow-x: hidden;
  .column {
    width: 100%;
    overflow: hidden;
  }

  #my-consommation {
    .tile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .number {
      font-size: 3rem;
      font-weight: 500;
      margin-top: 8px;
    }
    strong {
      font-size: 1.2rem;
      font-weight: 800;

      margin-block: 10px;
    }
  }

  #months-slider {
    cursor: grabbing;

    .tile {
      height: 80px;
      padding: 25px 5px;
    }
    .month {
      padding: 0px 0px;
      font-size: 1.2rem;
      font-weight: 600;
      transition: all 0.3s ease;
      color: var(--color-gray6);
      text-align: center;

      &.selected {
        font-size: 1.9rem;
        font-weight: 700;
        color: var(--color-text);
      }
    }
  }

  #billing-data {
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 20px 10px;
      height: 100%;
      margin: 0;

      .title {
        font-size: 1.13rem;
        font-weight: 600;
        text-align: center;
        margin-top: 10px;
        opacity: 0.9;
      }
      .content {
        font-size: 1.8rem;
        font-weight: 500;
      }
    }
  }

  #billing-total {
    table {
      thead {
        th {
          font-size: 0.9rem;
          padding: 4px;
        }
      }
      tbody {
        tr {
          background: none;

          &.subtile {
            background: var(--color-gray1);
          }
          td {
            font-size: 0.85rem;
            padding: 12px 10px;
            border-top: 1px solid var(--color-gray6);
          }
        }
      }
    }

    .big {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
}
