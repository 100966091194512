.dashboard {
  #bottom-bar {
    width: 100%;
    background: var(--color-gray0-transparent);
    backdrop-filter: blur(20px);
    height: 80px;
    position: fixed;
    bottom: 0;
    z-index: 100;
    // left: 0;
    // right: 0;
    inset-inline: 0;

    #support-btn {
      display: flex;
      left: 50px;
      bottom: 12px;
      position: fixed;
      background: var(--color-gray0);
    }

    #notification-btn {
      right: 50px;
      bottom: 12px;
      position: fixed;
      display: flex !important;
    }
  }

  aside {
    .navlink {
      padding-left: 10px;
    }
    .navlink-group {
      padding-left: 0;
      padding-right: 0;
      .navlink {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    #accountingfirm-selected {
      background: var(--color-infoBackground);
      opacity: 0.95;

      &:hover {
        background: var(--color-account) !important;
      }
      .content {
        font-size: 0.9rem;
        color: var(--color-active);
      }
    }

    .aside-company-step {
      background: var(--color-gray0-transparent);

      &#aside-company-pes {
        .link-container {
          overflow-y: auto;
          max-height: 100%;
        }
      }
    }

    .aside-company-step {
      border-radius: 0 0 15px 15px;
    }
  }
}
