#echeances {
  width: 100%;
  #timeline {
    display: flex;
    width: 100%;
    overflow-x: hidden;
    position: relative;
    flex-direction: column;
    border: 2px solid var(--color-gray2);
    border-radius: 30px;

    #jalons {
      width: fit-content;
      position: relative;
      width: 100%;
      height: 100vh;
      font-size: 0.95rem;
      background: white;
      border-radius: 30px;
      overflow-x: hidden;
      overflow-y: visible;

      .wrapper {
        display: flex;
        position: absolute;
        width: 100%;

        cursor: grab;
        overflow-y: visible;
      }

      .item {
        text-align: center;
        user-select: none;

        height: 100%;
        font-size: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 2px dashed var(--color-gray2);
        overflow-y: visible;
        margin: 0 !important;
        position: relative;
        display: flex;
        flex-direction: column;

        &:nth-child(odd) {
          .bar {
            background: var(--color-gray0) !important;
          }
        }
      }
      .date {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .bar {
        display: flex;

        height: 100vh;
        width: 100%;
        background: var(--color-gray0-transparent) !important;
      }
    }

    #nowbar {
      position: absolute;
      height: 100%;
      width: 3px;
      opacity: 0.4;
      height: 100%;

      background: var(--color-error);
      // #bar {
      // }
    }

    .date-item {
      white-space: nowrap;
      padding: 10px 60px;
      border-right: 2px dashed var(--color-gray3);
    }

    #companies {
      width: 100%;
      .company {
        width: 100%;
        background: lightgreen;
        // background: var(--color-gray1);
        border-radius: 30px;
        padding: 10px;
        .header {
          background: white;
          border-radius: 40px;
          font-size: 1.4rem;
          margin-bottom: 20px;
          position: absolute;
        }

        .questionExchangeItem {
          position: relative;
          width: 100%;
          height: 75px;
          margin-top: 10px;
          margin-bottom: 10px;
          border-bottom: 2px solid var(--color-gray2);
          display: flex;
          align-items: center;
          transition: all 0.2s ease;

          .expiration {
            top: 0;
            transform: translate(-22px, 18px);
            z-index: 2;
          }
          .start {
            top: 0;
            transform: translate(-12px, 18px);
            z-index: 1;
          }
          &:last-child {
            border-bottom: 2px solid transparent;
          }

          .bar {
            background: var(--color-infoBackground);
            border: 2px solid var(--color-infoBackground-hover);
            border-radius: 20px;
            height: 30px;
            position: absolute;
            width: 30px;
          }
        }

        .questionExchangeAnswerItem {
          position: relative;
        }
        .questionExchangeAnswerItem {
          .bar {
            background: var(--color-error);
          }
        }
        .exercice {
          width: 100px;
          height: 8px;
          background: var(--color-main);
          border-radius: 100px;
        }
      }
    }

    // tr {
    //   td {
    //     margin: 10px;
    //     padding: 20px 0;
    //   }
    //   .fill {
    //     margin: 10px 0;
    //     width: 100%;
    //     height: 5px;
    //     border-radius: 20px;
    //     background: var(--color-main);
    //     border-radius: 20px;
    //   }
    // }
  }
}
