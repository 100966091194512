.globalsearcher {
  background: "yellow";
  &:hover {
    background: var(--color-gray2);
  }
}

.searchItem {
  &:hover {
    background: var(--color-gray2);
  }
  &:focus,
  &:focus:hover {
    background: var(--color-account) !important ;
  }
}
