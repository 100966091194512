#suggestions-reponses {
  .navlink.selected,
  .navlink:hover {
    .icon {
      background: var(--color-gray2);
    }
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;

    .suggestion {
      display: flex;
      gap: 5px;
      align-items: center;
    }

    &.not-allowed {
      background-color: var(--color-gray2);
    }
  }
}
