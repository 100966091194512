#support-search-container {
  max-width: 500px;
  width: 100%;
  position: relative;
  margin-bottom: 10px;

  .search-icon {
    position: absolute;
    left: 7px;
    top: 7px;
    z-index: 5;
    width: 35px;
    height: 35px;
    background: var(--color-active);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 500px;
  }
  .icon {
    border-radius: 200px;
    background: var(--color-active);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    z-index: 4;
  }

  input {
    position: relative;
    padding-left: 50px;
    border-radius: 25px;
    z-index: 3;
  }

  .suggestions {
    position: absolute;
    top: 25px;
    padding-top: 25px;
    overflow: hidden;
    width: 100%;
    border-radius: 0 0 20px 20px;
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.09));
    max-height: 50vh;
    overflow-y: scroll;
    z-index: 2;

    .suggestion {
      border-bottom: 1px solid var(--color-gray0);
      display: flex;
      align-items: center;
      padding: 6px 15px 6px;
      position: relative;
      gap: 20px;
      text-align: left;
      font-size: 0.9rem;
      font-weight: 600;

      .icon {
        position: relative;
        aspect-ratio: 1;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.faq {
        .icon {
          color: var(--color-secondary);
          background: var(--color-secondary-background);
        }
      }
      &.tutorial {
        .icon {
          color: var(--color-warning);
          background: var(--color-warningBackground);
        }
      }
      &.category {
        .icon {
          color: var(--color-active);
          background: var(--color-infoBackground);
        }
      }

      &:last-child {
        border: none;
      }
      &:hover:not(.disabled) {
        cursor: pointer;
        background: var(--color-gray4);
      }

      .title {
        display: flex;
        gap: 10px;
        align-items: center;
      }
      .content {
        font-size: 0.75rem;
        font-style: italic;
        opacity: 0.7;
        margin-top: 7px;
      }
      .tags {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        gap: 5px;
        margin-top: 10px;
      }
      .tag {
        font-size: 0.65rem;
        font-weight: 700;
      }
      .chevron {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
      }
    }
  }
}
