#tutoriaux {
  #tags {
    gap: 5px;
  }
  .item {
    .icon {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      svg {
        fill: var(--color-warning);
      }
      background: var(--color-warningBackground);
    }
    .description {
      font-size: 0.9rem;
      font-style: italic;
    }
  }
}

#tutorial {
  .description {
    background: var(--color-glass);
    padding: 20px;
    border-radius: 15px;
    font-size: 0.95rem;
    line-height: normal;
  }
  #return-btn {
    position: relative;
    display: flex;
    cursor: pointer;
    align-items: center;
    margin: 50px 0 25px;
    gap: 10px;
    font-size: 1rem;
    font-weight: 600;

    .icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: var(--color-gray2);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      .icon {
        background: var(--color-gray4);
      }
    }
  }
  a {
    color: var(--color-main);
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      color: var(--color-active);
    }
  }
  h2 {
    font-size: 2rem;
    margin-bottom: 30px;
  }
  ol {
    list-style: none;
    counter-reset: item;
    padding-left: 0;
    li {
      counter-increment: item;
      margin-bottom: 30px;
    }
    li:before {
      margin-right: 10px;
      content: counter(item);
      background: var(--color-infoBackground);
      border-radius: 100%;
      color: var(--color-active);
      padding: 5px;
      font-weight: 600;
      font-size: 0.9rem;
      width: 20px;
      height: 20px;
      line-height: 20px;
      max-width: 40px;
      aspect-ratio: 1;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      // display: flex;
      // justify-content: center;
      // align-items: center;
    }
  }

  img {
    border-radius: 20px;
    margin-top: 25px;
    margin-bottom: 20px;
  }
}
