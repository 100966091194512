#details-reminder-modal {
  .body {
    overflow-y: hidden;

    div:has(> h3) {
      margin-bottom: 10px !important;
    }
  }

  #new-reminders {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;

    .selected {
      background: var(--color-infoBackground) !important;
      border: 2px solid var(--color-active);
      h4 {
        color: var(--color-active) !important;
      }
      svg {
        :not([color="none"]) {
          color: var(--color-active) !important;
        }
        :not([stroke="none"]) {
          stroke: var(--color-active) !important;
        }
        :not([fill="none"]) {
          fill: var(--color-active) !important;
        }
      }
    }
  }

  .company-header {
    .count {
      display: flex;
      gap: 5px;
      padding: 8px 12px;
      border-radius: 300px;
      font-weight: 600;
      font-size: 0.8rem;
      width: fit-content;

      background-color: var(--color-warningBackground);
      color: var(--color-warning);
    }
  }

  .exchange-body {
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    max-height: 100%;
    overflow-y: auto;
    margin: 0px;

    div:not(:first-child):has(> h3) {
      margin-top: 25px !important;
    }

    #schedulers {
      td[datatype="Action"],
      th[datatype="Action"] {
        width: 30px;
      }
    }

    #new-reminders .wrapper {
      div:has(> .toggle-item) {
        width: fit-content !important;
      }
      .toggle-item {
        margin: 0;
        padding: 0;
      }
    }

    .input-container,
    textarea {
      max-width: unset;
    }
  }
}

@media (max-width: 1300px) {
  #details-reminder-modal {
    .body {
      overflow-y: scroll;
      .exchange-body {
        max-height: unset;
        overflow: unset;
      }
      .mh-max {
        max-height: unset;
      }
      .o-hidden {
        overflow: unset;
      }
    }
  }
}
