#importation-feq {
  margin: auto;
  h2 {
    margin-top: 0;
  }

  #generation-questions {
    display: grid;
    width: 100%;
  }

  #loading-import,
  #generation-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &::backdrop {
      background: var(--color-infoBackgroundGlass);
    }

    backdrop-filter: blur(4px);
    width: 100vw;
    height: 100vh;
    position: fixed;
    inset: 0;
    z-index: 100;

    .window {
      background: var(--color-gray0);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 50px;
      border-radius: 40px;
    }

    h2 {
      font-size: 2.4rem;
      padding-top: 1rem;
      padding-bottom: 0.2rem;
      color: var(--color-text);
      text-align: center;
      max-width: 600px;
      width: 80%;
    }

    p {
      max-width: 500px;
      width: 80%;
      text-align: center;
      color: var(--color-text-light);

      &.subtitle {
        font-size: 1.45rem;
        font-weight: 600;
      }
      &.detail {
        font-size: 1.1rem;
        max-width: 500px;
      }
    }
  }

  // tbody {
  //   tr {
  //     .toggle-item {
  //       margin: 4px;
  //     }
  //     &:hover {
  //       background: var(--color-gray1);
  //     }
  //     td {
  //       vertical-align: middle;
  //       font-weight: 500;
  //       &.icone {
  //         text-align: center;
  //         color: var(--color-text-light);
  //         svg {
  //           fill: var(--color-text-light);
  //         }
  //         &.clickable {
  //           background-color: var(--color-gray2);
  //           margin: 5px;
  //           border: 5px solid var(--color-gray0);
  //           &:hover {
  //             background-color: var(--color-gray5);
  //           }
  //         }
  //       }
  //       .input-container {
  //         padding: 0;
  //         margin: 0;
  //         align-items: center;
  //         select {
  //           border: none;
  //           background-color: var(--color-gray2);
  //           &:hover {
  //             cursor: pointer;
  //           }
  //         }
  //         input {
  //           border: none;
  //           background-color: var(--color-gray2);
  //           padding-top: 0.2rem;
  //           padding-bottom: 0.2rem;
  //         }
  //       }
  //     }
  //   }
  // }
}

#pre-step {
  .entry-import {
    transition: all;

    .header {
      display: flex;
      align-items: flex-start;
    }

    .user {
      display: flex;
      gap: 10px;
      align-items: center;

      background-color: var(--color-gray1);
      border-radius: 50px;

      padding: 5px;
      padding-right: 15px;
      margin-top: 5px;

      width: fit-content;

      p {
        margin: 0 !important;
        font-size: 0.8rem;
        font-weight: 600;
      }
    }

    &.last {
      margin-top: 10px;
      margin-bottom: 10px;
      position: relative;

      .icon {
        max-width: 90px;
      }

      .count {
        display: flex;
        align-items: center;
        gap: 5px;

        border-radius: 50px;
        padding: 2px 7px 2px 2px;
        margin-left: 5px;

        font-size: 0.8rem;
        font-weight: 700;

        color: white;
        background-color: var(--color-success);

        width: fit-content;
        svg {
          width: 18px;
          height: auto;
        }
      }

      .details {
        width: 100%;
        padding: 10px;
        border-top: 2px solid var(--color-gray1);
      }
    }
  }
}

#entries.modal {
  overflow: auto;
  .body {
    overflow-x: visible;
  }
}

@media (max-width: 380px) {
  #pre-step {
    .header {
      flex-direction: column;
      position: relative;
    }
    .count {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
