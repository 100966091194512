@use "../../../styles/variables.scss";

#dossiers {
  width: 100%;

  .search-input {
    input {
      border-radius: 50px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    svg {
      top: 17px;
    }
  }
  tr {
    position: relative;
    td {
      position: relative;
      font-size: 0.8rem;
      * {
        font-size: 0.8rem;
      }
    }
  }
  #filter-btn-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  #company-filter-bar {
    position: relative;

    .toggle-item {
      margin-bottom: 0;
    }

    .close-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      border-radius: 60%;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      background: var(--color-gray1);
      * {
        cursor: pointer;
      }
      &:hover {
        background: var(--color-gray4);
      }
    }

    .input-container .select-input {
      margin-bottom: 0;
      padding-bottom: 0;
      margin-top: 0;
      padding-top: 0;
    }
    .wrapper {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }
  }

  table {
    th {
      vertical-align: middle;
    }
    td {
      vertical-align: middle;
      &.number {
        text-align: center;
      }
    }
    tr.restreint {
      cursor: not-allowed;
      background-color: var(--color-gray2);
      &:hover {
        background-color: var(--color-gray2);
      }
    }
    tr.isDeleted {
      background-color: var(--color-errorBackground);
      color: var(--color-error);
      &:hover {
        background-color: var(--color-errorBackground-hover) !important;
      }
    }
  }

  .deleted {
    color: var(--color-error);
  }

  tr {
    position: relative;
    td {
      position: relative;

      #graphs {
        transform: translateY(-100%);
        width: max-content;
        max-width: calc(100vw - 385px);
        overflow: hidden;
        position: absolute;
        background-color: var(--color-gray0);
        filter: drop-shadow(0px 19px 30px rgba(0, 0, 0, 0.062));

        &::after {
          content: "";
          position: absolute;
          border-width: 6px;

          border-style: solid;
          border-color: var(--color-gray0) transparent transparent transparent;

          top: 100%;
          left: 17px;
        }
        .chart {
          max-width: 150px;
          aspect-ratio: 3/2;
        }
      }
    }
  }
}

@media (max-width: variables.$media-small-screen) {
  #dossiers {
    tr {
      position: relative;
      #graphs {
        max-width: 90vw;
      }
    }
  }
}
