@use "../../../../../../styles/variables.scss";

#accounts-container.pending {
  .account-category-container {
    margin-top: 15px;

    &:first-child {
      margin-top: 0;
    }
  }
  .account-container {
    .question-group-container {
      .simple-question {
        .question {
          background-color: var(--color-blue-light);
          border-radius: 20px;
          padding-bottom: 7px;
          &.denied {
            background-color: var(--color-errorBackground);
            .denied-container {
              margin-left: 10px;
              padding-left: 5px;
              border-left: 2px solid var(--color-error);
              .previews {
                margin-inline: 0px;
              }
            }
          }
        }
        .icon-button {
          margin-left: auto;
        }
      }
    }
  }
}

@media (max-width: variables.$media-tablet) {
  #accounts-container.to-validate {
    .account-container {
      .question-group-container {
        .title-container {
          grid-template-columns: 1fr auto;
        }
      }
    }
  }
}
