#accounts-container.to-process {
  margin-bottom: 10px;
  .account-container {
    .question-group-container {
      .simple-question {
        grid-template-columns: 1fr 2fr;
        padding: 0px;

        .question {
          gap: 5px;
          border-radius: 20px;
          height: 100%;

          .previews {
            margin-inline: 0px;
            // .preview-container {
            //   max-width: 120px;
            // }
          }
        }
        .answer {
          gap: 5px;
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          border: none !important;
          padding: 5px 10px;
          height: 100%;

          .previews {
            margin-inline: 0px;
            .preview {
              // max-width: 180px;
              .img-container {
                background-color: var(--color-gray2);
              }
            }
          }
          .actual {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: auto;
            margin-bottom: 5px;
          }
        }
      }

      .additionnal-content {
        border: 1px solid var(--color-error);
        border-radius: var(--size-borderRadius);
        padding: 10px;
        background-color: var(--color-errorBackground);
        > div > p {
          font-weight: 600;
          color: var(--color-error);
        }
        .input-container {
          margin: 5px 0px 15px 0px;
          textarea {
            max-width: 100%;
          }
        }
        .text-input {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 5px;
          align-items: flex-start;
          .input-container {
            margin-bottom: 0px;
          }
          .btn-container {
            margin-top: 25px !important;
          }
        }
      }
      .buttons {
        h4 {
          margin: 0 10px;
        }
        button {
          margin: 0;
        }
      }
    }
  }
}
