#support-modal {
  .content {
    padding: 0;
  }
  #home {
    .item {
      margin: 0px;
      padding: 20px;
      padding-left: 20px;
      border-radius: 12px;
      cursor: pointer;
      background: var(--color-gray1);
      color: var(--color-text);
      height: 100%;
      align-items: center;
      gap: 10px;
      font-size: 0.95rem;
      font-weight: 500;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: 1fr;

      span {
        text-align: start;
      }

      &:hover {
        background: var(--color-gray2);
      }
    }
  }
}
