#releases {
  width: 98%;
  max-width: 900px;

  img {
    max-height: 150px;
    aspect-ratio: inherit;
    border-radius: 15px;
    margin: 40px 0 20px;
    max-width: 100%;
    width: unset;
    height: unset;
  }

  #main-release {
    border: 2px solid var(--color-infoBackground);
    background: var(--color-blue-light);

    .header {
      background: var(--color-blue-light-hover);
    }
  }

  .release {
    padding: 0px;
    overflow: hidden;
    hr {
      margin: 0;
    }

    .header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      padding: 10px;
      background: var(--color-gray1);

      .version {
        font-size: 1rem;
      }
    }
    .body {
      padding: 18px 18px 30px;
      h2 {
        &:first-child {
          margin-top: 5px;
        }
        font-size: 1.5rem;
        margin-top: 20px;
      }
      h3 {
        &:first-child {
          margin-top: 5px;
        }
        font-size: 1.25rem;
        margin-top: 20px;
      }

      ul {
        padding-left: 15px;
        list-style: disc;
        font-size: 0.92rem;
      }
    }
  }
}
