#connecteurs {
  .tiles-container {
    display: grid;
    grid-template-columns: 4fr 4fr;
    gap: 15px;
    .tile {
      margin: 0;
    }
  }

  #connector-list {
  }
}
