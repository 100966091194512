#question-exchanges {
  #multiple_download {
    .input-container {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .wrapper {
    .question-exchanges-container {
      .user {
        display: flex;
        gap: 5px;
        align-items: center;
        padding: 2px;
        padding-right: 10px;
        border-radius: 50px;
        width: fit-content;
        font-size: 0.9rem;

        background-color: var(--color-gray2);
      }
      .item {
        .user {
          max-width: 200px;
        }
        .btn-container {
          max-width: 500px;
        }
        &:hover {
          background-color: transparent;
        }
      }
      textarea,
      .input-container {
        max-width: unset;
      }
    }
  }
}
