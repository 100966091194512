#regularisation {
  #message {
    h2 {
      font-size: 2.2rem;
    }
    strong {
      font-size: 1.35rem;
    }
  }
}
