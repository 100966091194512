#exercices {
  table {
    th[datatype="Action"],
    tr[datatype="Action"],
    th[datatype="Importation"],
    tr[datatype="Importation"] {
      width: 170px;
      * {
        width: 100%;
      }
    }
    th[datatype="Du"],
    tr[datatype="Du"],
    th[datatype="Au"],
    tr[datatype="Au"] {
      width: calc((100% - 100px - 170px) / 2);
    }
    th[datatype="Cloturé"],
    tr[datatype="Cloturé"] {
      width: 100px;
      text-align: end;
    }
  }
}
