#create-billing-request-guide {
  // #customer-informations {
  // }

  #list-bank-accounts {
    #selected_sepa {
      border: 1px solid var(--color-gray2);
      background: var(--color-gray1);
      border-radius: 10px;
      padding: 10px;
      margin-top: 10px;
      margin-right: 10px;
      margin-bottom: 20px;
      p {
        font-size: 0.95rem;
      }
      strong {
        font-size: 0.95rem;
        margin-right: 10px;
      }
    }

    .wrapper {
      max-height: 200px;
      border: 1px solid var(--color-gray2);
      border-radius: 10px;
      overflow-y: auto;
      padding: 10px;
      margin-right: 10px;
    }

    .success-item {
      padding: 6px;
      &.selected {
        background: var(--color-infoBackground);
        border: 2px solid var(--color-blue-background);
      }
      .details {
        background: var(--color-gray1);
        border-radius: 10px;
        padding: 3px 5px;
        strong {
          font-size: 0.85rem;
          margin-right: 10px;
          margin-left: 5px;
        }
        p {
          font-size: 0.85rem;
          margin-bottom: 1px;
        }
      }
    }
  }

  #regularisation {
    .tile {
      background: var(--color-gray1);
    }
  }
}
