#previews {
  .preview {
    position: relative;
    text-align: center;
    max-width: 344px;
    width: 100%;
    height: 202px;
    margin: auto;
    background-color: var(--color-gray3);
    border-radius: var(--size-borderRadius);
    overflow: hidden;
    .modify-file {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: grid;
      grid-template-columns: auto auto;
      transition: all 0.5s ease-in-out;
      .chose-other,
      .delete {
        padding: 1rem;
        display: grid;
        grid-template-rows: 50% auto;
        align-items: end;
        justify-items: center;
        color: var(--color-text);
        svg {
          color: var(--color-gray9);
        }
        p {
          padding-top: 1rem;
          font-size: 0.9rem;
          text-align: center;
        }
        &:hover {
          background-color: var(--color-gray3);
          cursor: pointer;
        }
      }
    }
  }
}
