#collaborator {
  .informations {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .name {
      font-size: 1.4rem;
      font-weight: 600;
    }
  }

  .role-container,
  .user-infos {
    max-width: 500px;
    margin-left: 0;
    .select_role {
      position: relative;
      .input-container {
        margin: 0px;
        padding: 0px;
        .select-input {
          padding-block: 6px;
        }
      }

      .loader {
        position: absolute;
        bottom: 50%;
        right: 20px;
        transform: translateY(50%);
      }
    }
  }

  .logs {
    width: fit-content;
    max-width: 500px;
    margin-left: 0;

    .success {
      color: var(--color-success);
    }
    .error {
      color: var(--color-error);
    }
  }

  .message-box {
    margin-block: 14px;
  }
}
