#accountingFirm-deleted {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  text-align: center;

  .icon {
    max-width: 200px;
    width: 100% !important;
    svg {
      width: 70%;
      height: 70%;
    }
  }
}
