@use "../../styles/variables.scss";

#dashboard-index {
  padding-bottom: 60px;

  #gestion-cabinet {
    #cartouche {
      border: 1px solid var(--color-gray2);
      border-radius: 500px;
      padding: 10px;
      width: fit-content;
      h3 {
        text-align: left;
      }
      &.clickable:hover {
        background: var(--color-gray2);
      }
    }
  }

  #discover-tile {
    background: var(--color-main);
    height: 280px;
    position: relative;
    opacity: 0.8;
    overflow: hidden;

    > svg {
      position: absolute;
      opacity: 0.2;
      z-index: 0;
      right: 0;
      top: -20px;
    }

    &:hover {
      background: var(--color-active);
    }

    #footer {
      position: absolute;
      bottom: 20px;
      max-width: 200px;
      h2 {
        color: white;
      }

      button {
        margin-left: 0;
        margin-bottom: 0;
        background: black;
        color: white;
        font-weight: 600;
        svg {
          color: white;
        }
      }
    }
  }

  #all-files {
    width: 100%;
    height: 280px;
    background: var(--color-main);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    opacity: 0.8;

    svg {
      position: absolute;
      top: 15%;
      opacity: 0.15;
    }
    h2 {
      color: white;
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: 35px;
      left: 0;
      right: 0;
    }

    &:hover {
      background: var(--color-active);
    }
  }

  #rapid-access {
    .tile {
      margin-top: 0 !important;
      height: 210px;
      position: relative;
      opacity: 0.9;
      transition: all 0.1s ease;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 30px;
      svg {
        z-index: 0;
        position: absolute;
        top: 18%;
        right: 28%;
        opacity: 0.2;
        transition: transform 0.6s ease;
      }
      h3 {
        width: 85%;
        margin: auto;
        bottom: 20px;
        font-size: 1.4rem;
        line-height: 1.6rem;
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        transition: all 0.1s ease;
      }
      &#users,
      &#suggestions,
      &#facturation,
      &#informations,
      &#connectors {
        h3,
        svg {
          color: var(--color-info);
        }
        background: var(--color-account);
        &:hover {
          background: var(--color-info);
        }
      }

      &:hover {
        opacity: 1 !important;
        h3 {
          color: white !important;

          opacity: 1 !important;
        }

        svg {
          color: white !important;
          opacity: 0.4 !important;
          transform: translateX(50px) scale(0.8) translateY(-50px);
        }
      }
    }
  }

  #rapid-import {
    width: 100%;
    height: 100%;
    border: 3px dashed var(--color-infoBackground-hover);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    background: var(--color-infoBackground);
    border-radius: var(--size-borderRadius);
    opacity: 0.9;

    .icon-button {
      background: var(--color-info);
    }
    &,
    * {
      cursor: pointer;
    }
    &:hover {
      background: var(--color-infoBackground-hover);
      border-color: var(--color-active);
      opacity: 1;
      .icon-button {
        background: var(--color-active);
      }
    }
    svg {
      color: white;
      stroke: white;
      * {
        stroke: white;
      }
    }
    h2 {
      font-weight: 700;
      color: var(--color-main);
    }
  }

  // //////////////////////////////////////////////////////////////////////////////

  #gestion-cabinet {
    #demo-companies {
      p {
        font-size: 0.8rem;
        font-weight: 600;
        color: var(--color-info);
      }
      background: var(--color-infoBackground);

      #progress-bar {
        height: 10px;
        overflow: hidden;
        width: 100%;
        border-radius: 500px;
        background: var(--color-gray3);
        position: relative;
        #bar {
          position: absolute;
          width: 100%;
          top: 0;
          bottom: 0;
          left: 0;
          background: var(--color-info);
        }
      }

      &.warning {
        background: var(--color-warningBackground);
        p {
          color: var(--color-warning);
        }
        #progress-bar {
          #bar {
            background: var(--color-warning);
          }
        }
      }
      &.error {
        background: var(--color-errorBackground);

        p {
          color: var(--color-error);
        }
        #progress-bar {
          #bar {
            background: var(--color-error);
          }
        }
      }
    }
    #collaborators {
      gap: 0;
      margin-top: 20px;
      .profil-picture {
        margin: 0;
        margin-left: -3px;
      }
    }
  }

  #main-accountingfirm {
    top: 18px;
    position: sticky;
  }

  #echeances {
    .swiper {
      padding-inline: 50px;
    }
    .wrapper {
      max-height: 500px;
      overflow-y: auto;
      border-radius: 30px;
      padding: 5px;

      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    #periods {
      font-size: 1.15rem;

      * {
        font-weight: 700;
      }
      .selected {
        .number {
          color: var(--color-active) !important;
        }
        background: var(--color-infoBackground);
        &:hover {
          .number {
            color: white !important;
          }
        }
      }
    }

    .item {
      padding: 4px 4px;
      position: relative;
      border-radius: 25px;
      .header {
        background: var(--color-gray1);
        padding: 3px 18px 3px 3px;
        border-radius: 60px;
      }

      .bar {
        display: none;
        position: absolute;
        height: 100%;
        width: 5px;
        background: var(--color-error);
        left: 0;
        top: 0;
        bottom: 0;
      }
    }

    .company-item {
      position: relative;
      border-radius: 30px;

      #header-bar {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: var(--color-error);
        height: 5px;
        width: 100%;
      }

      .cartouche {
        border: 2px solid var(--color-gray1);
        border-radius: 300px;
        padding: 5px;
        margin: 0;
        cursor: pointer;
        h3 {
          margin: 0;
        }

        &:hover {
          background: var(--color-gray2);
        }
      }
      .indicator {
        position: absolute;
        top: -15px;
        left: -15px;
        background: var(--color-error);
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      #company-exchanges {
        > .body {
          overflow-y: hidden;
          overflow-x: hidden;
        }
      }
    }
    .number {
      font-size: 0.9rem;
      font-weight: 800;
      display: flex;
      gap: 3px;
      align-items: center;
      justify-content: center;

      &.null {
        opacity: 0.6;
      }
      &.green {
        color: var(--color-success) !important;
        .bulle {
          background-color: var(--color-success);
        }
      }
      &.yellow {
        color: var(--color-warning) !important;
        .bulle {
          background-color: var(--color-warning);
        }
      }
      &.red {
        color: var(--color-error) !important;
        .bulle {
          background-color: var(--color-error);
        }
      }
      &.blue {
        color: var(--color-info) !important;
        .bulle {
          background-color: var(--color-info);
        }
      }

      .bulle {
        border-radius: 50%;
        background: var(--color-text-light);
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 25px;
        height: 25px;
        font-size: 0.85rem;
        font-weight: 600;
        padding: 5px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .tooltip {
      .number {
        color: var(--color-text-soft);

        .bulle {
          background: var(--color-text-soft);
        }
      }
    }

    .pes-visualizer {
      max-width: unset !important;
    }

    #echeances-wrapper {
      border: 2px solid var(--color-gray1);
      overflow-y: auto;
      padding: 2px;
      background: var(--color-gray1);

      .item {
        background: var(--color-gray0-transparent);
        border: 2px solid var(--color-gray1);
        padding: 5px;
        border-radius: 15px;
        margin-bottom: 2px;

        &:hover {
          background: var(--color-gray2);
        }
      }
    }
  }

  #reminders {
    .sort {
      display: flex;
      gap: 20px;
      align-items: center;
      .input-container {
        margin: 0;
        padding: 0;
        max-width: 280px;
      }
    }
    .company-item {
      .title {
        font-size: 0.9rem;
        font-weight: 600;
      }

      .details {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px 5px;
        margin-top: 6px;
      }
      .expiration-date,
      .count,
      .reminder {
        display: flex;
        align-items: center;
        border-radius: 50px;
        padding: 5px 15px 5px 10px;
        gap: 2px;
        justify-content: flex-start;
        font-weight: 600;
        font-size: 0.75rem;
      }

      .gray {
        background-color: var(--color-gray1);
        color: var(--color-gray18);
        svg {
          color: var(--color-gray18);
          stroke: var(--color-gray18);
        }
      }
      .info {
        background-color: var(--color-infoBackground);
        color: var(--color-info);
        svg {
          color: var(--color-info);
          stroke: var(--color-info);
        }
      }
      .warning {
        background-color: var(--color-warningBackground);
        color: var(--color-warning);
        svg {
          color: var(--color-warning);
          stroke: var(--color-warning);
        }
      }
      .error {
        background-color: var(--color-errorBackground);
        color: var(--color-error);
        svg {
          color: var(--color-error);
          stroke: var(--color-error);
        }
      }
    }
  }

  #companies-to-treat {
    .wrapper {
      font-weight: 500;
      button {
        max-width: unset;
        margin: 0px;
      }
      .number {
        background: var(--color-gray1);
        color: var(--color-gray18);

        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1/1;
        height: 25px;
        padding: 4px 6px;
        border-radius: 150px;
        font-size: 0.8rem;
        font-weight: 700;
      }
    }
  }
  #last-processed-company {
    > button {
      margin: 0px;
      max-width: unset;
    }
  }

  #rapid-import-modal {
    #file-header {
      #informations {
        background: var(--color-infoBackground);
        color: var(--color-active);
        padding: 15px 10px;
        border-radius: 10px;
        margin-top: 10px;
        margin-bottom: 5px;
        gap: 15px;
        display: flex;
        align-items: center;
        font-size: 0.9rem;
        position: relative;
        cursor: pointer;
        .edit-btn {
          display: none;
        }
        &:hover {
          .edit-btn {
            display: flex;
          }
        }
      }

      .edit-btn {
        background: var(--color-main);
        border-radius: 1000px;
        padding: 8px;
        position: absolute;
        right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          color: white;
        }
      }

      #empty-file {
        margin-top: 40px;
        margin-bottom: 0px;
        background: var(--color-gray1);
        border-radius: 10px;
        padding: 25px 18px;
        &:hover {
          background: var(--color-gray2);
        }
        em {
          font-size: 0.85rem;
        }
      }
    }
    .tab-wrapper {
      margin-top: 20px;
      margin-bottom: 0px;
    }
    // AUTO SEARCH

    #auto-search-result {
      padding: 10px;
      border-radius: 50px;
      border: 1px solid var(--color-gray2);
      align-items: center;

      .name {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0px;
      }
    }

    /// SEARCH
    ///

    #search-results {
      border: 2px solid var(--color-gray1);
      padding: 5px;
      border-radius: 10px;
      margin: 10px 0;
      max-height: 200px;
      overflow-y: scroll;

      .radiogroup {
        padding: 0;
      }

      .result {
        padding: 5px;
        border-radius: 10px;
        margin: 2px 0;
        cursor: pointer;
        border: 1px solid transparent;

        p {
          margin: 0;
          line-height: 0.9rem;
        }
        .name {
          font-size: 0.85rem;
          font-weight: 600;
          margin-bottom: 3px;
        }
        .siret {
          font-style: italic;
          font-size: 0.8rem;
        }

        &:hover {
          background: var(--color-gray2);
        }

        &.selected {
          background: var(--color-infoBackground);
          border: 1px solid var(--color-active);
        }
      }
    }

    // CREATION
    #creation-dossier {
      h3 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: clamp(20px, 2rem, 30px);
      }
    }
  }

  #gestion-cabinet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.5rem;
    h3 {
      margin-bottom: 0;
      text-align: center;
    }

    .picture {
      margin: 1rem auto 0;
    }
  }

  #logout-btn {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

#newFeaturesModal {
  z-index: 5;
  border-radius: 40px !important;
  #header {
    margin-top: 10px;
    z-index: 100;
    display: flex;
    flex-direction: row;
    padding: 20px;
    gap: 5px;
    align-items: center;

    #version {
      color: var(--color-active);
      font-size: 1.6rem;
      font-weight: 700;
      margin-right: 15px;
    }
    #published {
      font-style: italic;
    }
  }

  #viewer {
    height: 100%;
    max-height: 50vh;
    padding: 0 !important;
    border-radius: 25px;
    overflow-y: auto;
    #content {
      padding: 25px 35px 35px;
    }

    img {
      width: 50%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    ul {
      list-style: disc;
      padding-left: 15px;
      margin-top: 10px;
      li {
        margin-bottom: 5px;
      }
    }
  }

  .item {
    cursor: pointer;
    padding: 12px;
    border-radius: 10px;
    margin-bottom: 4px;

    &:hover {
      background: var(--color-gray2);
    }
    &.selected {
      background: var(--color-infoBackground);
      color: var(--color-active);
    }
  }
}

@media (max-width: 1300px) {
  #dashboard-index {
    #gestion-cabinet {
      order: 3;
    }
  }
}

@media (max-width: variables.$media-tablet) {
  #dashboard-index {
    padding-bottom: 20px;
    #echeances {
      .wrapper {
        max-height: 85vh;
      }
    }
  }
}
