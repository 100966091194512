#generation-questions {
  aside {
    height: unset;
  }

  #summary {
    .item {
      position: relative;
      .edit-icon {
        right: 10px;
        top: 10px;
        position: absolute;
        opacity: 0.4;
      }
      &:hover {
        .edit-icon {
          opacity: 1;
        }
      }
    }
  }

  .params {
    display: grid;
    gap: 12px;
    grid-template-columns: 5fr 2fr;
    .tile {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .journaux-container {
      width: 100%;

      .button {
        button {
          border-radius: 10px;
          border: 2px solid var(--color-gray1);
        }
        display: flex;
        justify-content: center;
      }
    }
  }

  #categories {
    overflow: hidden;

    .category-item {
      opacity: 0.5;
      background: var(--color-gray2);
      border: 2px solid transparent;

      &:hover {
        opacity: 1;
      }

      &.selected {
        background: var(--color-infoBackground);
        border-color: var(--color-active);
        opacity: 1;
      }
    }
  }

  #accounts {
    #sortbar {
      display: flex;
      gap: 6px;
      align-items: center;
      font-weight: 600;

      .title {
        margin-right: 10px;
        font-size: 0.95rem;
        color: var(--color-gray15);
        font-weight: 600;
      }
      .item {
        display: flex;
        margin: 0px;
        padding: 7px 14px;
        border-radius: 20px;
        cursor: pointer;
        background: var(--color-gray1);
        font-size: 0.95rem;

        svg {
          margin-right: 10px;
        }

        &.selected {
          background: var(--color-infoBackground);
          color: var(--colo-active);
          &:hover {
            background: var(--color-infoBackground);
          }
        }
        &:hover {
          background: var(--color-gray3);
        }
      }
    }

    .wrapper {
      background: none;
      border: 2px solid var(--color-gray1);
      border-radius: 8px;
      padding: 5px;
      max-height: 400px;
      overflow-y: auto;

      .account-item {
        display: grid;
        grid-template-columns: 20px 1fr;
        gap: 10px;
        font-size: 0.82rem;
        font-weight: 600;
        padding: 5px;
        margin: 3px 0;
        border-radius: 5px;
        align-items: center;
        background: var(--color-gray1);
        border: 2px solid transparent;
        width: 100%;
        max-width: unset;
        text-align: left;
        &:hover {
          background: var(--color-gray3);
          border-color: var(--color-gray3);
        }
        &.selected {
          background: var(--color-infoBackground);
          color: var(--color-main);
          border-color: transparent;
          svg {
            color: var(--color-main);
          }
          &:hover {
            background: var(--color-infoBackground);

            border-color: var(--color-active);
          }
        }
      }
    }
  }

  .drop-zone {
    strong {
      font-size: 1.45rem;
      margin-bottom: 0.7rem;
    }
    p {
      font-size: 1.1rem;
      margin-bottom: 0.2rem;
    }
    .subtitle {
      margin-top: 0.5rem;

      font-weight: 500;
    }

    .icon-inactive {
      padding: 15px;
      border-radius: 10px;
      background-color: var(--color-blue-light);
      margin-bottom: 1rem;

      svg {
        margin-bottom: 0;
      }
    }
  }

  #journal-modal {
    .content {
      padding: 20px;
    }
  }
}
