#gestion-categories {
  .categories-container {
    max-width: 600px;
    margin-right: 0;
    margin-left: 0;
    table {
      thead {
        tr {
          th {
            // background-color: var(--color-gray0);
            padding: 0.5rem;
          }
        }
      }
      tbody {
        tr {
          background-color: var(--color-gray0);
          td:nth-of-type(1) {
            width: fit-content;
            text-align: center;
            .icone {
              padding: 8px;
              color: var(--color-text-light);
              border-radius: 7px;

              &.clickable {
                background-color: var(--color-gray2);
                &:hover {
                  background-color: var(--color-gray5);
                }
              }
            }
          }
          td:nth-of-type(2) {
            width: 85%;
            input {
              max-width: 100%;
            }
          }
          td:nth-of-type(3) {
            min-width: 180px;
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
          }
          td {
            vertical-align: middle;
            font-weight: 500;
            padding: 8px;

            .input-container {
              padding: 0;
              margin: 0;
              align-items: center;
              select {
                border: none;
                background-color: var(--color-gray2);
                &:hover {
                  cursor: pointer;
                }
              }
              input {
                border: none;
                background-color: var(--color-gray2);
                padding-top: 0.2rem;
                padding-bottom: 0.2rem;
              }
            }
          }
        }
      }
    }
    td.icon-choice {
      border-top: 2px solid var(--color-gray2);
      text-align: unset !important;
      p {
        margin: 0;
        margin-bottom: 0.5rem;
      }
    }
    .category-name-input {
      display: flex;
      align-items: center;
      gap: 5px;
      .accept,
      .discard {
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--size-borderRadius);
      }
      .accept {
        color: var(--color-success);
        background-color: var(--color-successBackground);
        &:hover {
          color: var(--color-gray0);
          background-color: var(--color-success);
        }
      }
      .discard {
        color: var(--color-error);
        background-color: var(--color-errorBackground);
        &:hover {
          color: var(--color-gray0);
          background-color: var(--color-error);
        }
      }
    }
    button.add {
      margin-left: 0;
    }
    .category-type-container {
      display: grid;
      grid-template-columns: auto 2rem;
      gap: 5px;
      align-items: center;
      padding-right: 5px;
      .category-type {
        width: fit-content;
        border-radius: var(--size-borderRadius);
        text-align: center;
        padding: 0.3rem 1rem;
        &.default {
          background-color: var(--color-gray2);
          color: var(--color-gray17);
        }
        &.perso {
          background-color: var(--color-main);
          color: var(--color-gray0);
        }
      }
    }
  }
}
