#dossier {
  .header {
    display: flex;
    justify-content: space-between;

    .company-picture {
      width: clamp(80px, 20vw, 120px) !important;
      height: clamp(80px, 20vw, 120px) !important;
    }
    h1 {
      text-transform: capitalize;
    }
    .subtitle {
      margin-bottom: 0;
    }

    .notification-btn {
      cursor: pointer;
      padding: 15px !important;
    }
  }
  #notifications {
    background: var(--color-gray1);
    border: 2px solid var(--color-gray2);
    &:hover {
      background: var(--color-gray2);
    }
    &.activated {
      background: var(--color-warningBackground);
      h4 {
        color: var(--color-warning);
      }
      border-color: var(--color-warningBackground);
      &:hover {
        background: var(--color-warningBackground-hover);
      }
    }
  }
  #new-importation {
    padding: 20px 20px;
    height: 100%;
    background: color-mix(in hsl, var(--color-info), transparent 10%);
    position: relative;
    overflow: hidden;
    font-size: 1.65rem;
    font-weight: 500;
    opacity: 1;

    .rond {
      // background: var(--color-info);
      background: none !important;
      border: 3px solid white;
      opacity: 0.8;

      svg {
        opacity: 1;
        position: relative;
        transform: none;
        opacity: 1;
        z-index: 1;
        top: unset;
        right: unset;
        left: unset;
        color: white;
        transform: unset !important;
      }
    }
    h3 {
      opacity: 1;
      bottom: 0px;
      margin: 0;
      color: white;
    }
    &:hover {
      background: var(--color-main);
      .rond {
        opacity: 0.7;
      }
    }
  }
  #pes-rapid-icons {
    .tile {
      position: relative;
      overflow: hidden;
      padding-left: 15px;

      .number {
        position: absolute;
        top: 15px;
        right: 15px;
        background: var(--color-active);
        border-radius: 200px;
        color: white;
      }

      svg {
        z-index: 0;

        transition: transform 0.6s ease;
      }
      h3 {
        z-index: 1;
        font-size: 1.15rem;
        margin: 0;
      }
    }
  }

  #gestion-dossier {
    .tile {
      position: relative;
      overflow: hidden;

      // display: grid;
      // grid-template-columns: auto 1fr;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 20px 15px;
      height: 100%;

      opacity: 0.8;

      h3 {
        margin: 0;
      }
      svg {
        margin: 10px 0;
      }

      h4 {
        font-size: 1.15rem;
        text-align: left;
      }

      &:hover {
        background: var(--color-gray2);
      }
    }
  }

  #restore-company {
    position: relative;

    h1 {
      text-transform: none;
    }

    .tile {
      position: relative;
      overflow: hidden;
      padding: 20px clamp(30px, 30%, 120px);

      #bin {
        transform: rotateZ("330deg");
        opacity: 1;
        top: 20px;
      }
    }
  }

  #import-new-fec-btn {
    display: grid;
    grid-template-columns: 50px 1fr 25px;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 12px;
    font-size: 0.95rem;
    font-weight: 600;
    gap: 12px;
    margin-bottom: 20px;
    height: unset;

    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--color-blue-light);

      border-radius: 50%;
      width: 50px;
      height: 50px;
    }
  }

  .column {
    a {
      margin: 0;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 10px;
    }
    .title {
      font-size: 1rem;
    }
  }

  .informations {
    margin-top: 0;
    position: relative;

    .row {
      color: var(--color-text-light);
      align-items: center;
      &.flex-start {
        align-items: flex-start;
      }
    }
  }

  #graphs {
    height: 100%;
    width: 100%;
    overflow: hidden;
    .swiper {
      .swiper-slide {
        width: 100%;
        .chart {
          height: 100%;
          // aspect-ratio: 3/2;
          // width: auto;
        }
      }
    }
  }

  #affected {
    h3 {
      margin-bottom: 0;
    }
    position: relative;

    .search-input {
      input {
        border-radius: 50px;
      }
    }

    &.nonModifiable {
      .cache {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: var(--color-gray8);
        opacity: 0.3;
        z-index: 2;
        cursor: not-allowed;
      }
    }

    .wrapper {
      display: flex;
      flex-wrap: wrap;
      .affected-user {
        padding: 5px 5px 5px 5px;
        width: 100%;
        display: flex;
        gap: 5px;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid var(--color-gray1);
        &:last-child {
          border: none;
        }

        span {
          margin-right: 5px;
          font-size: 0.9rem;
          font-weight: 600;
        }

        .icon-button {
          color: var(--color-text);
        }

        &.readonly {
          padding-right: 15px;
        }
      }
    }
  }

  button {
    border-radius: var(--size-borderRadius);
  }

  #echeances {
    &.wrapper {
      max-height: 100%;
      overflow-y: auto;
      border-radius: 30px;

      display: flex;
      flex-direction: column;
      flex: 0 1 auto;
      gap: 5px;
    }
    #periods {
      font-size: 1.15rem;

      * {
        font-weight: 700;
      }
      .selected {
        .number {
          color: var(--color-active) !important;
        }
        background: var(--color-infoBackground);
        &:hover {
          .number {
            color: white !important;
          }
        }
      }
    }

    .item {
      border-radius: 25px;
      background: var(--color-gray0);
      padding: 7px 18px 7px 27px;
    }

    .number {
      color: var(--color-text-light);
      font-size: 0.9rem;
      font-weight: 800;
      display: flex;
      gap: 3px;
      align-items: center;
      justify-content: center;

      &.null {
        opacity: 0.6;
      }
      &.green {
        color: var(--color-success) !important;
        .bulle {
          background-color: var(--color-success);
        }
      }
      &.yellow {
        color: var(--color-warning) !important;
        .bulle {
          background-color: var(--color-warning);
        }
      }
      &.red {
        color: var(--color-error) !important;
        .bulle {
          background-color: var(--color-error);
        }
      }
      &.blue {
        color: var(--color-info) !important;
        .bulle {
          background-color: var(--color-info);
        }
      }

      .bulle {
        border-radius: 50%;
        background: var(--color-text-light);
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 25px;
        height: 25px;
        font-size: 0.85rem;
        font-weight: 600;
        padding: 5px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .tooltip {
      .number {
        color: var(--color-text-soft);

        .bulle {
          background: var(--color-text-soft);
        }
      }
    }

    // #echeances-wrapper {
    //   border: 2px solid var(--color-gray1);
    //   overflow-y: auto;
    //   padding: 2px;
    //   background: var(--color-gray1);

    //   .item {
    //     background: var(--color-gray0-transparent);
    //     border: 2px solid var(--color-gray1);
    //     padding: 5px;
    //     border-radius: 15px;
    //     margin-bottom: 2px;

    //     &:hover {
    //       background: var(--color-gray2);
    //     }
    //   }
    // }
  }
}
