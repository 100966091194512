#exchange-question-piechart,
#exchange-reminders {
  color: var(--color-text-light);
  font-size: 0.9rem;
  font-weight: 800;
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;

  .bulle {
    border-radius: 50%;
    background: var(--color-text-light);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 25px;
    height: 25px;
    font-size: 0.85rem;
    font-weight: 600;
    padding: 5px;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  .number {
    color: var(--color-text-soft);
    font-size: 0.9rem;
    font-weight: 800;
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: center;
    .bulle {
      background: var(--color-text-soft);
    }
  }

  .null,
  &.null {
    opacity: 0.6;
  }
  .done,
  .green {
    color: var(--color-success) !important;
    .bulle {
      background-color: var(--color-success);
    }
  }
  .toDo,
  .yellow {
    color: var(--color-warning) !important;
    .bulle {
      background-color: var(--color-warning);
    }
  }
  .red {
    color: var(--color-error) !important;
    .bulle {
      background-color: var(--color-error);
    }
  }
  .blue {
    color: var(--color-info) !important;
    .bulle {
      background-color: var(--color-info);
    }
  }
}
