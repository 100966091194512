#graphs {
  .chart {
    // max-width: 300px;
    height: 100%;
    margin: 0;
    order: 2;

    &.selected {
      max-width: unset;
      order: 1;
      grid-column: 1 / -1;
    }
  }

  .hidden {
    display: none !important;
  }
}
