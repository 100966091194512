#collaborators {
  .collaborator-container {
    h3 {
      font-size: 1rem;
      font-weight: 600 !important;
      margin-bottom: 0;
    }
  }
  .gestion {
    .tile {
      height: 100% !important;
      justify-content: space-between;

      .input-container {
        margin: 0px;
        padding: 0px;
      }
    }
  }

  #categories-accessibles {
    .category-item,
    .tile-choice {
      cursor: unset;
    }

    .category-item {
      font-size: 0.8rem;
      padding: 15px 5px 5px;
      gap: 2px;
    }
  }
}
