#pes-gestion {
  #accounts-container {
    .account-container {
      margin-bottom: 10px !important;
      .simple-question {
        textarea {
          font-size: 0.95rem;
          font-weight: 500;
        }
      }

      &:last-child {
        margin-bottom: 50px !important;
      }
    }
  }

  > .header {
    background: transparent;
    padding: 4px !important;
    border: 1px solid var(--color-gray2);

    .title {
      margin: 0;
      padding: 7px;
      background-color: transparent;
      border: 2px solid var(--color-gray1);
      border-radius: var(--size-borderRadius);
      font-size: 1.25rem;
      color: var(--color-text-light);
      .shape {
        background-color: var(--color-gray2) !important;
        svg {
          color: var(--color-gray15);
        }
      }
    }
    &.toProcess {
      margin-top: 10px !important;
      .shape {
        background-color: var(--color-secondary-background) !important;
        svg {
          color: var(--color-secondary);
        }
      }
    }
    &.processed {
      margin-block: 10px 0px !important;
      .shape {
        background-color: var(--color-successBackground) !important;
        svg {
          color: var(--color-success);
        }
      }
    }
    &.archived {
      margin-top: 10px !important;
    }
    &.pending {
      margin-top: 10px !important;
      .shape {
        background-color: var(--color-warningBackground) !important;
        svg {
          color: var(--color-warning);
        }
      }
    }
  }
  .content {
    flex-wrap: wrap;
  }
  #accounts-container {
    flex: 1;
  }
}
