#help {
  width: 95%;
  margin-bottom: 60px;

  .category,
  .title {
    .icon {
      aspect-ratio: 1/1;
      width: 30px;
      height: auto;

      background-color: var(--color-infoBackground);
      padding: 8px;
      border-radius: 8px;
      svg {
        aspect-ratio: 1/1;
        height: auto;
        width: 100%;
      }
    }
  }

  #breadcrumbs {
    display: flex;
    gap: 2px;
    align-items: center;
    background: var(--color-gray1);
    padding: 3px;
    border-radius: 50px;
    width: fit-content;

    .choices {
      display: flex;
      gap: 2px;
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
    }

    .category {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      font-weight: 600;
      font-size: 0.9rem;
      padding: 6px 18px 6px 6px;
      background: var(--color-gray2);

      .icon {
        display: block;
        width: 24px;
        height: 24px;
        padding: 4px;
        border-radius: 30px;
        color: white;
        background: var(--color-active);
      }
      // }
    }

    &.mobile {
      position: relative;
      align-items: flex-start;
      width: fit-content;
      min-width: 200px;

      position: relative;
      z-index: 2;
      margin: 0;
      padding: 0;

      &.active .choices {
        display: flex;
      }
      &.inactive .choices {
        display: none;
      }

      .choices {
        backdrop-filter: blur(8px);
        position: absolute;
        z-index: 2;
        margin-top: 0;
        display: flex;
        flex-direction: column-reverse;
        background-color: var(--color-glass);
        border-radius: 0 0 20px 20px;
        overflow: hidden;
        width: 100%;
        top: 100%;

        .item {
          font-weight: 500;
          border-top: 2px solid var(--color-gray3);
          &.selected {
            display: none;
          }

          &:hover {
            background-color: var(--color-gray2);
          }
        }
      }
      .item {
        width: 100%;
        .category {
          background-color: inherit;
        }
      }
      &.active {
        border-radius: 15px 15px 0 0;
      }
      &.inactive {
        border-radius: 30px;
      }
    }
  }

  #tutoriaux {
  }

  #aside {
    width: 100%;
    max-width: unset;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: var(--color-gray0);
      gap: 10px;
      padding: 30px 30px;
      border-radius: 20px;
      margin: 0;
      text-align: center;
      height: 100%;
      font-weight: 600;

      &.clickable:hover {
        background-color: var(--color-gray1);
        .icon {
          background-color: var(--color-warningHover);
        }
      }
      .icon {
        aspect-ratio: 1/1;
        width: 70px;
        border-radius: 300px;
        height: auto;
        margin-inline: auto;
        background-color: var(--color-blue-light);
        padding: 18px;

        svg {
          aspect-ratio: 1/1;
          height: auto;
          width: 100%;
          color: var(--color-active);
        }
      }
      p {
        text-align: center;
        margin: 0px auto 10px;
        width: 100%;
        font-weight: 600;
      }

      .count {
        position: absolute;
        background: var(--color-text-soft);
        color: white;
        border-radius: 300px;
        bottom: -10px;
        right: -10px;
        width: fit-content;
        min-width: 23px;
        font-size: 0.85rem;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.selected {
        border-color: var(--color-active);
        background-color: var(--color-infoBackground);
        .icon {
          background-color: var(--color-active);
          svg {
            color: white;
          }
        }
      }

      &:has(.badges) {
        text-align: start;
        padding: 20px;
        grid-template-columns: auto 1fr;
        grid-template-rows: 1fr;
        align-items: start;
        .badges {
          margin-top: 5px;
          display: flex;
          gap: 5px;
          svg {
            aspect-ratio: 1/1;
            width: 15px;
          }
        }
      }
    }
  }
}
