#mes-preferences-de-notifications {
  margin-left: 0;
  fieldset:first-child {
    margin-top: 10px;
  }

  table {
    tr.topic {
      td {
        border-bottom: 1px solid var(--color-gray1);
        p {
          margin: 0;
        }
      }
    }

    .title {
      font-weight: 700;
      font-size: 1.2rem;
      padding-bottom: 20px;
    }
  }
}
