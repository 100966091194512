@use "../../../../../../styles/variables";

#accounts-container.to-send {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .account-category-container {
    margin-top: 0px;
    &:first-of-type {
      margin-top: 0;
    }
  }

  .account-container {
    padding: 6px;
    hr {
      margin: 4px 0 8px;
    }
    &:last-child {
      margin-bottom: 0 !important;
    }
    .question-group-container {
      padding: 5px;
      margin-bottom: 4px;
      gap: 5px;

      .single-button {
        display: grid;
        padding: 13px;
        border-radius: 10px;

        &:hover {
          background: var(--color-gray3);
          svg {
            fill: var(--color-gray17);
          }
        }
      }

      .simple-question {
        align-items: flex-start;
        grid-template-columns: 1fr auto;
        padding: 0px;

        .copy-text-input {
          padding-block: 2px;
          align-items: center;

          textarea {
            padding-block: 0;
            padding-left: 10px;
            align-items: center;
          }
        }

        .buttons {
          margin: 0;
          justify-content: center;
        }
      }
    }
  }

  #send-button {
    margin-bottom: 70vh;
  }
}

#send_confirmation_modal {
  .content {
    padding: 0 20px;
    .body {
      padding: 0 30px;
    }
  }

  .title {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 35px 1fr;
    gap: 10px;
    align-items: center;
    h3 {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
    }
  }

  .tile {
    margin-top: 0;
    .title {
      margin-top: 0;
    }
  }
  #send-body {
    overflow-y: hidden;
    max-height: 100%;
  }

  #recipients {
    overflow-y: auto;

    .wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 8px;
    }

    .recipient {
      padding: 2px 15px 2px 2px;
      border-radius: 30px;
      align-items: center;
      background: var(--color-gray1);
      display: grid;
      grid-template-columns: 30px 1fr;
      grid-gap: 5px;
      gap: 5px;
      font-size: 0.85rem;
      font-weight: 600;
    }
  }

  #dueDates {
    .toggle-item {
      margin: 0px;
      padding: 0px;
      margin-top: 18px;
      margin-bottom: 3px;
    }
  }

  .input-container,
  textarea {
    max-width: unset;
  }
}

@media (max-width: variables.$media-small-screen) {
  #send_confirmation_modal {
    .body {
      overflow-y: auto;
    }

    .mh-max.o-hidden {
      overflow: unset;
    }
  }
}

@media (max-width: variables.$media-tablet) {
  #accounts-container.to-send {
    #send-button {
      margin-bottom: 0px;
    }
  }
}

@media (max-width: variables.$media-phone) {
  #accounts-container.to-send {
    grid-template-columns: auto;
    .account-container {
      .question-group-container {
        .simple-question {
          .buttons {
            margin-left: auto;
          }
        }
      }
    }
  }
}
