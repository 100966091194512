@use "../../../../../../styles/variables.scss";

#pes-gestion {
  #accounts-container.to-validate,
  #question-blueprints.to-validate {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;

    h3 {
      margin: 0;
      padding-left: 15px;
    }

    .tile {
      margin: 0;
    }
    .select-input {
      padding: 0px;
      width: fit-content;
    }

    .category-actions {
      margin-bottom: 15px !important;
    }
    .categories-rapid-access {
      padding: 15px;
      .categories-rapid-access-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        gap: 5px;
        margin-top: 2px;
        margin-bottom: 0;

        .category-rapid-access {
          position: relative;
          padding: 25px 15px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          gap: 15px;
          background-color: var(--color-account);
          border-radius: var(--size-borderRadius);
          .shape {
            width: 60px !important;
            padding: 15px !important;
            .icon {
              width: 100%;
              height: 100%;
              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
          * {
            color: var(--color-active);
          }
          &:hover {
            cursor: pointer;
            background-color: var(--color-infoBackground);
          }
          .title {
            font-weight: 600;
          }
          .number {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 10px;
            right: 10px;
            font-size: 0.9rem;
            font-weight: 600;
            aspect-ratio: 1;
            background-color: var(--color-active);
            color: var(--color-white);
            padding: 3px 8px;
            border-radius: 50px;
          }
        }
      }
    }

    .account-container {
      margin-bottom: 5px !important;
      > .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
      }
      .no-input {
        justify-content: flex-start;
        align-items: center;
        button {
          &.active {
            visibility: visible;
          }
          &.inactive {
            visibility: hidden;
          }
        }
        h4,
        h3 {
          cursor: pointer;
        }
        .icon-button {
          margin-left: 1rem;
          svg {
            stroke-width: 3px;
          }
        }
        &:hover {
          border-radius: 10px;
        }
      }
      .add-question-group {
        margin: 0;
        margin-top: 15px;
      }

      .question-group-container {
        .main {
          border-right: 1px solid var(--color-gray1);
          padding-right: 10px;
        }
        &.newgroup {
          margin-top: 10px;
          border: 1px solid var(--color-gray2);
          padding: 15px;
          .announcement-container {
            padding-top: 0px;

            margin-bottom: 10px;

            .input-container {
              margin-bottom: 0;
              padding-bottom: 0;
            }

            textarea {
              max-width: 100%;
            }
          }

          .questions {
            margin-top: 0px;
            padding: 5px;
            height: 100%;
            max-height: 300px;
            overflow-y: auto;
            overflow-x: hidden;

            .preview-container {
              width: 130px;
            }
          }
        }
        .announcement-container {
          padding-top: 5px;

          margin-bottom: 0px;
          textarea {
            max-width: 100%;
          }
        }

        .questions {
          margin-top: 3px;

          .preview-container {
            width: 130px;
          }
        }

        button {
          margin: 0;
          padding: 0.7rem auto;
          &.add {
            margin-bottom: 1rem;
            font-size: 0.9rem;
          }
        }

        .title-container {
          margin: 0;
          grid-template-columns: 1fr;
          position: relative;

          .input {
            .input-container {
              padding: 0;
              margin: 0;
              input {
                width: 100%;
                max-width: 400px;
              }
              textarea {
                max-width: 100%;
                height: fit-content;
              }
            }
            button {
              margin: 0;
            }
          }

          h4 {
            margin-top: 5px;
            margin-left: 0.7rem;
            line-height: 1.1rem;
            cursor: text;
          }
        }

        .add-question-btn {
          margin-top: 15px;
          margin-left: 5px;
          margin-bottom: 5px;
          border-radius: 10px;
          display: grid;
          padding: 13px;
          border-radius: 10px;
          background: var(--color-gray1);
          color: var(--color-gray6);
          &.option-btn {
            border-radius: 300px;
            padding: 10px;
          }
          svg {
            fill: var(--color-gray6);
            stroke: var(--color-gray6);
            path {
              stroke: var(--color-gray6);
            }
          }
          &:hover {
            background: var(--color-gray3);
            color: var(--color-gray17);
            svg {
              fill: var(--color-gray17);
              path {
                stroke: var(--color-gray17);
              }
            }
          }
        }

        .add-question-btn {
          display: grid;
          grid-template-columns: 40px 1fr;
          padding: 7px 15px 7px 10px;
        }

        .simple-question {
          position: relative;
          grid-template-columns: 1fr auto;
          justify-content: center;
          margin-top: 0px !important;
          margin-bottom: 0 !important;
          align-items: center;

          &.partial-lettering-mode {
            grid-template-columns: 20px 1fr auto;
          }

          textarea {
            vertical-align: middle;
            padding: 8px 10px;
          }

          .buttons {
            margin: 0;
            justify-content: center;
            .accept,
            .discard {
              padding: 5px;
            }
            .accept {
              color: var(--color-success);
              background-color: var(--color-successBackground);
              &:hover {
                color: var(--color-gray0);
                background-color: var(--color-success);
              }
            }
            .discard {
              color: var(--color-error);
              background-color: var(--color-errorBackground);
              &:hover {
                color: var(--color-gray0);
                background-color: var(--color-error);
              }
            }
          }
          .entries {
            position: absolute;
            top: 0;
            right: 155px;
            z-index: 1;

            border-radius: 8px;
            background-color: var(--color-glass);
            // padding: 5px;
            overflow: hidden;
            backdrop-filter: blur(5px);
            filter: drop-shadow(5px 5px 10px var(--color-gray2));

            table {
              border: unset;
              background-color: unset;
              th,
              tr {
                background-color: unset;
              }
              tbody {
                tr:hover {
                  background-color: var(--color-gray1) !important;
                }
                tr.totaux {
                  background-color: var(--color-gray2);
                  &:hover {
                    background-color: var(--color-gray2) !important;
                  }
                }
              }
            }
          }
        }
      }
      &.new {
        border-color: var(--color-gray2);
        background-color: var(--color-gray0);
        input {
          max-width: 100%;
        }
      }
    }

    #newAccount {
      label {
        color: var(--color-link);
        margin-left: 5px;
        opacity: 0.9;
        margin-bottom: 0px;
      }
      > .grid {
        padding-inline: 15px;
        padding-bottom: 15px;
      }

      > .grid > .tile {
        padding: 15px;
      }
    }
    .suggestions {
      border: 1px solid var(--color-gray3);
      max-height: 500px;
      overflow-x: hidden;
      overflow-y: auto;

      .suggestion {
        font-size: 0.9rem;
        margin-bottom: 0;
        padding: 5px 10px;

        &:hover {
          background: var(--color-blue-background);
          color: var(--color-active);
        }

        &.selected {
          background: var(--color-text-light);
          color: var(--color-gray0);

          &:hover {
            background: var(--color-text);
            color: var(--color-active);
          }
        }
      }

      p:last-of-type {
        margin-bottom: 0 !important;
      }
    }
  }

  @media (max-width: variables.$media-small-screen) {
    #accounts-container.to-validate {
      grid-template-columns: auto;
    }
  }

  @media (max-width: variables.$media-small-screen) {
    #accounts-container.to-validate .account-container .question-group-container .simple-question .entries {
      right: 0;

      table {
        border: unset;
        background-color: unset;
        th,
        tr {
          background-color: unset;
        }
        tbody {
          tr:hover {
            background-color: var(--color-gray1) !important;
          }
          tr.totaux {
            td[datatype="Totaux"] {
              display: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: variables.$media-tablet) {
    #accounts-container.to-validate {
      grid-template-columns: auto;
      .account-container {
        .question-group-container {
          .simple-question {
            .buttons {
              margin-left: auto;
            }
          }
        }
      }
    }
  }

  @media (max-width: variables.$media-phone) {
    #accounts-container.to-validate {
      grid-template-columns: auto;
      .account-container {
        .question-group-container {
          .title-container {
            .input {
              flex-direction: column;
              align-items: center;
            }
          }
          .simple-question {
            .buttons {
              margin-left: auto;
            }
          }
        }
      }
    }
  }
}
