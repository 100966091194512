#tickets-creation {
  #support-search-container {
    width: 100%;
    max-width: var(--size-input);
    .suggestions {
      .suggestion {
        cursor: pointer;
        &:hover {
          background-color: var(--color-gray2);
        }
      }
    }
  }

  #report-selected {
    > svg {
      width: 35px;
      height: 35px;
    }
  }
}
