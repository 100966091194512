#collaborators.page {
  #users {
    #header {
      h2 {
        margin-left: 0 !important;
      }
    }

    #affectations {
      .company-item {
        font-size: 0.9rem;
        text-transform: lowercase;
      }
    }

    .navlink {
      font-size: 1.05rem;
      padding: 5px;
      border-radius: 300px;
      margin-bottom: 5px;

      &:hover {
        background: var(--color-gray2);
      }
      &.selected {
        background: var(--color-active);
        color: white;
        .form {
          background: var(--color-main) !important;
        }
      }
    }
    .userlist-item {
      margin-bottom: 5px !important;

      &.selected {
        background: var(--color-active);
        h4,
        em {
          color: white;
        }

        &.selected:hover {
          background: var(--color-active);
        }
      }
    }
  }
}
